import React from 'react';
import "../Style/style.css";
import RunningPackage from "../Assets/RunningPackage.jpg";
import HotelPackage from "../Assets/HotelPackage.jpg"
import Banner from '../Banner/banner.js'


function Race () {
    return (

    
        <div class="race-info" id="race-info">
            
            <h3>Race Details</h3>
            <p>Join us for the 5K/10K </p>
            <p>This walk/run will be on the Catherine Valley Trail run in the town of Horseheads NY. During the run, guests will be cheered on at each kilometer with a refreshment station halfway through the course! At the finish line, there will be refreshments, food, and music to continue the fun.  This will be a "timed" event</p>
            <p>Event details and schedule</p>
            <p>Event Schedule :</p>
            <ul class="bold-list">
                <li>Race Theme : Run like a Rhino</li>
                <li>Date: October 26, 2024</li>
                <li>Run Start Time: 9:00am (10K), 9:30am (5K) (please arrive at least 30 min early</li>
                <li>Run Start Location: Murray Athletic Center</li>
                <li>Address: 170 Huck Finn Road, Horseheads, ny 14845</li>
                <li>Run End Location & Festivities: 170 Huck Finn Road, Horseheads NY 14845</li>
                <li>5K/10K Trail Map : <a href='https://app.luminpdf.com/viewer/669f0965364e559838d037db'> 2024 Trail Map </a></li>
                <li class="prize-list"><div width="250"><span class="prize">COMPLIMENTARY PRIZE PACKAGE GIVEAWAY!</span><br></br><span class="register-prize">All registered Adult Participants </span> will receive an entry into these 2 drawings.<br></br>
            Winners Do Not need to be Present to Win!<br></br></div><div class="prize-images"><img src={HotelPackage} alt="flyer for Hotel Package" height="300" width="225"></img> <img src={RunningPackage} alt='confluence running package flyer' height="300" width="232"></img></div></li>
            </ul>
            <div>Event Information:</div>
            <ul>
                <li>Walker / Runner Shirts will be available for pick-up on the day of the event. We will email you a 
                confirmation of your registration and time ranges to receive your t-shirt.**Shirt size is not guaranteed. 
                First come first serve basis.**</li>
                <li>Although the (10K) run begins at 9:00 am and (5k) 9:30 am, we recommend getting there at least 30mins before the start to pick up your bibs and t-shirt and to find parking (if you are driving)</li>
                <ul>
                    <li>We are anticipating that finding parking will be difficult, for those arriving closer to start time</li>
                </ul>
                <li>LOCKERS WILL NOT BE PROVIDED.</li>
                <li>Reach out to <a href="mailto:borderbuddiesmissions@gmail.com">borderbuddiesmissions@gmail.com</a> with any questions</li>
            </ul>
            <Banner />
            <h3 style={{marginTop: '1rem'}}>About BBM's Fundraising Efforts</h3> 
          
           
           <p>Fundraising for the construction efforts by Border Buddies Ministries, Inc., a 501(c)(3) non-profit organization to continue rebuilding classrooms in <b>Tegucigalpa, Honduras</b>. Border Buddies ministries is led by the missionaries in Honduras, Mike and Glenda Miller, who were Horseheads locals and still have family in the Big Flats area. Border buddies is all<b> about bringing community together</b> and the 5K/10K is a great way to have a fun family event while raising funds to the organization.</p>
            <p>For more information about the 5K/10K Event : <b style={{ color: 'black'}}>Heather Waters</b> at <a href="mailto:borderbuddiesmissions@gmail.com">borderbuddiesmissions@gmail.com</a><br></br>
            For more information about Border Buddies Ministries go to <a href="https://borderbuddies.org">Border Buddies Ministries – BRINGING
            THE HEART OF CHRIST TO THE HEART OF HONDURAS</a> or contact <b style={{ color: 'black'}}>Mike and Glenda Miller </b> at <a href="mailto:mjgm2004@yahoo.com">mjgm2004@yahoo.com</a></p>
            <h4>Where did  “Run like a rhino” come from?</h4>
            <p>An excerpt from The Barbarian Way by  Erwin McManus (pg 137-138)</p>
            <p>
"...rhinos can run at thirty miles an hour, which is pretty fast when you consider how much weight
they're pulling... Just one problem with this phenomenon.  Rhinos can only see thirty feet in front of
them.  Can you imagine something that large moving in concert as a group, plowing ahead at thirty 
miles an hour with no idea what's at thirty-one feet?  You would think that they would be far too timid
to pick up full steam, that their inability to see far enough ahead would paralyze them to immobility.  But
with that horn pointing the way, rhinos run forward full steam ahead without apprehension, which leads
us to their name.
</p>
<p>Rhinos moving together at full speed are known as a crash.  Even when they're just hanging around 
enjoying the watershed, they're called a crash because of their potential... I think that's what we're 
supposed to be.  That's what happens when we become barbarians and shake free of domestication
and civility.  The church becomes a crash.  We become an unstoppable force.  We don't have to pretend 
we know the future.  Who cares that we can see only thirty feet ahead?  Whatever's at thirty-one feet needs
to care that we're coming and better get out of the way.
</p>
<p>We need to move together as God's people... and become the human version of the rhino crash.  The
future in uncertain, but we need to move toward it with confidence... We need to stop wasting our time
and stop being afraid of what we cannot see and do not know.  We need to move forward full force because
of what we do know."</p>
<b><p>Check out photos/videos past BBM 5K/10K Races!</p></b>
<div class="photo-slideshow"><iframe src="https://player.vimeo.com/video/827629539?h=0493c5eac3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="photo-iframe" title="Untitled"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
<div class="elfsight-app-b2ed302e-813d-41e4-a095-39aad02b14c1"></div>
        </div>

    )
}

export default Race;