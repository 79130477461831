import React from 'react';
import "../Style/style.css";
import nedstractor from "../Assets/nedstractor.png";
import solutionscredit from "../Assets/solutionscredit.png";
import confluence from "../Assets/confluence.png";
import hilliard from "../Assets/hilliard.png";
import haughey from "../Assets/haughey.png";
import multiMediaServices from "../Assets/multiMediaServices.jpeg";
import lynch from '../Assets/lynch.png';
import tireworld from '../Assets/tireworld.jpg';
import evansNewLogo from '../Assets/evansNewLogo.jpg';
import pastricks from '../Assets/pastricks.jpg';
import chemung from '../Assets/chemung.png';
import chadhammond from '../Assets/chadhammond.png';
import pipsboutique from '../Assets/pipsboutique.jpg'
import guthriecu from '../Assets/guthriecu.jpg'
import corningabstract from '../Assets/corningabstract.jpg'
function Sponsors () {
    return (
        <div class="sponsor-info">
            <div class="sponsor-title">
                <h3>2024 Sponsors</h3> 
                <a href="https://docs.google.com/document/d/e/2PACX-1vRtwmHrd7pw2kajUWyXAD2xxTPCzza03zxZpbDJdRwZAU5jtzacnStg-1xJL7QK0M156iPuryFNoXd8/pub">Sponsorship Levels</a>
            </div>
            < div className="individual-sponsors">
                <div className="rhino">
                    <h3>Rhino Sponsors</h3>
                    <div className="rhino-sponsors">
                    <div className="rhino-1">
                        <img src={nedstractor} width="300" height="75"alt="black and orange logo that states Neds Tractor with a tire for the O"></img><br></br>
                        <a href="https://www.nelstractor.com" style={{textAlign: "center"}}>Nel's Tractor </a>
                    </div>
                    <div className="rhino-2">
                        <img src={confluence} width="150" height="123"alt="green circle with running man"></img><br></br>
                        <a href="https://www.confluencerunning.com/pages/corning" style={{textAlign: "center"}}>Confluence Running Company</a>
                    </div>
                    </div>
                    
                </div>
                
    </div>
            <div className="individual-sponsors">
                <div className="rhino">
                    <h4>Construction Sponsors</h4>
                    <div className="rhino-sponsors">
                    <div className= "rhino-1">
                        <img src={hilliard} width="62" height="75" alt = "logo for hilliard corp"></img><br></br>
                        <a href="https://www.hilliardcorp.com/">Hilliard Corporation</a>
                    </div>
                    <div className="rhino-2"> 
                        <img src={evansNewLogo} width="200" height="85" alt="logo for tire world  "></img><br></br>
                        <a href="https://evansroofingcompany.com/">Charles F. Evans Company</a>
                    </div>
                    <div className="rhino-3">
                        <img src={solutionscredit} width="200" height="42" alt="logo for solutions  CU"></img><br></br>
                        <a href="https://www.solutionscu.org">Solutions Credit Union</a>
                    </div>
                    <div className="rhino-4">
                        <img src={chadhammond} width="200" height="59" alt="logo for hammond law"></img><br></br>
                        <a href="https://www.chadhammondlaw.com">Chad R. Hammond Law</a>
                    </div>
                    <div className="rhino-5">
                        <img src={pipsboutique} width="150" height="150" alt="logo for pips boutique"></img><br></br>
                        <a href="https://www.pipsboutiquecorningny.com/">Pip's Boutique</a>
                    </div>
                    <div className="rhino-6">
                        <img src={guthriecu} width="200" height="100" alt="logo for guthrie cu"></img><br></br>
                        <a href="https://guthriefcu.org">Guthrie Community Credit Union</a>
                    </div>
                    <div className="rhino-7">
                        <img src={corningabstract} width="200" height="130" alt="logo for pips boutique"></img><br></br>
                        <a href="https://www.facebook.com/corningabstract/">Corning Abstract & Closing Services</a>
                    </div>
                    <div className="rhino-8">
                        <img src={chemung}  alt="logo for chemung soft water "></img><br></br>
                        <a href="https://www.chemungsoftwater.com/">Chemung Soft Water</a>
                    </div>
                    </div>
                </div>
    </div>  {/*}
               <div className="individual-sponsors">
                <div className="rhino">
                    <h4>Rhino Sponsors</h4>
                    <div className="rhino-sponsors">
                    <div className="rhino-1">
                        <img src={nedstractor} width="200" height="45"alt="black and orange logo that states Neds Tractor with a tire for the O"></img><br></br>
                        <a href="https://www.nelstractor.com" style={{textAlign: "center"}}>Nel's Tractor </a>
                    </div>
                    <div className="rhino-2">
                        <img src={confluence} width="100" height="82"alt="green circle with running man"></img><br></br>
                        <a href="https://www.confluencerunning.com/pages/corning" style={{textAlign: "center"}}>Confluence Running Company</a>
                    </div>
                    </div>
                </div>
    </div> 
                <div className="individual-sponsors">  
                <div className="construction">
                    <h5>Construction Sponsors</h5>
                    <div className="construction-sponsors">
                    <div className="construction-1">
                        <img src={solutionscredit} width="200" height="42" alt="logo for solutions  CU"></img><br></br>
                        <a href="https://www.solutionscu.org">Solutions Credit Union</a>
</div>
                    <div className="construction-1">
                        <img src={hilliard} width="62" height="75" alt = "logo for hilliard corp"></img><br></br>
                        <a href="https://www.hilliardcorp.com/">Hilliard Corporation</a>
                    </div>
                    <div className="construction-2">
                      <img src={haughey} width="144" height="50" alt = "logo for Haughey Funeral Home"></img><br></br>
                        <a href="https://haugheyfuneralhome.com/">Haughey Funeral Home</a>
                    </div>
                    <div className="construction-4">
                        <img src={multiMediaServices} width="144" height="50" alt = "logo for Multi Media Services"></img><br></br>
                        <a href="https://mmsny.com/">Multi Media Services</a>
                    </div>
                    </div>
                    <div className="construction-sponsors2">
                    <div className="construction-5">
                         <img src={lynch} width="200" height="42" alt="logo for lynch funeral home"></img><br></br>
                        <a href="https://lynchsfuneralhome.com/">Lynch Funeral Home</a>
                    </div>
                    <div className="construction-6">
                        <img src={tireworld} width="200" height="42" alt="logo for tire world  "></img><br></br>
                        <a href="https://www.tireworldofcorning.com/">Tire World of Corning</a>
                    </div>
<div className="construction-7"> 
                        <img src={evansNewLogo} width="200" height="85" alt="logo for tire world  "></img><br></br>
                        <a href="https://evansroofingcompany.com/">Charles F. Evans Company</a>
                    </div>
                     <div className="construction-8">
                        <img src={pastricks} width="200" height="95" alt="logo for pastricks "></img><br></br>
                        <a href="https://www.facebook.com/pastricks1/">Pastricks Sporting Good</a>
</div>
                
                    </div>
                   {/*} <div className="construction-sponsors3">
                    <div className="construction-9">
                        <img src={chemung}  alt="logo for chemung soft water "></img><br></br>
                        <a href="https://www.chemungsoftwater.com/">Chemung Soft Water</a>
                    </div> 
</div>
                    </div>
    </div>*/}
           </div>         
                
                    
            )
}

export default Sponsors;