
function UnderBanner() {
return(
    
<div className="under-banner">

    <div className="sticky-navbar-info">
      <h5> Run Like a Rhino 3rd Annual 5K/10K Race</h5>
      <h6>Saturday, October 26, 2024</h6>
    </div>
    <div class="sticky-register-button-section">
        <a className="sticky-register-button" href="https://docs.google.com/forms/d/e/1FAIpQLScExLpGXlhOmxqHuiiTTK99-zBjTwsWIX0EsZgcmaGWK_tH6g/viewform?usp=sf_link">Register</a>
    </div>
  </div>

  )
}

export default UnderBanner;
  
