import React from 'react';
import "../Style/style.css";
import BBMnewBanner from '../Assets/BBMnewBanner.png'

function Banner () {
    return (
        <div style={{backgroundColor: 'white', width: "80%", marginTop: "5rem", margin: 'auto'}}>
            <div class="card bg-dark text-white border-0 h-75 ">
                <img class="card-img h-90" src={BBMnewBanner} alt="pictures of construction workers building the school"/>
        
            </div> 
        </div>

    )
}

export default Banner;