import React from 'react';
import UpperBanner from "./Components/Banner/UpperBanner";
import Race from "./Components/RaceInfo/RaceInfo";
import Sponsors from "./Components/Sponsors/sponsors";
import StickyNav from "./Components/Sticky/sticky";
import UnderBanner from './Components/UnderBanner/UnderBanner'
import "../src/Components/Style/style.css"

function App() {
  return(
    <div>
      <header>
        <StickyNav />  
      </header>
      <UpperBanner />
      <UnderBanner />
      <Race />
      <Sponsors />
      <footer>
        <p class="footer">Website created by <a href="https://ashley-christie.netlify.app">Ashley Christie</a></p>
      </footer>
    </div>
  )
}

export default App;