import React from 'react';
import "../Style/style.css";
import Flyer from '../Assets/Flyer.jpg'

function UpperBanner () {
    return (
        <div style={{backgroundColor: 'white', width: "45%", paddingTop: "5rem", margin: 'auto'}}>
            <div class="card bg-dark text-white border-0 h-75 ">
                <img class="card-img" src={Flyer} alt="BBM Race Flyer"/>
        
            </div> 
        </div>

    )
}

export default UpperBanner;