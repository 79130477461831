import React, {useState, useEffect } from "react"
import BBMLogo from '../Assets/BBMLogo.png';
import "../Style/style.css"


function StickyNav () {
  const [isVisible, setIsVisible] =
  useState(true);
    const [ setHeight] = useState(0)

    useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      return () => 
       window.removeEventListener("scroll", listenToScroll); 
  },)
  
  const listenToScroll = () => {
    let heightToHideFrom = 500;
    const winScroll = document.body.scrollTop || 
        document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll < heightToHideFrom) {  
         isVisible && setIsVisible(false);
    } else {
         setIsVisible(true);
    }  
  };

  return(
    <div id="contianer">
    { isVisible &&
<div className="sticky-navbar">
    <div className="sticky-navbar-logo-section">
      <img src={BBMLogo} alt="logo" className="sticky-navbar-logo" />
    </div>
    <div className="sticky-navbar-info">
      <h5> Run Like a Rhino 3rd Annual 5K/10K Race</h5>
      <h6>Saturday, October 26, 2024</h6>
    </div>
    <div class="sticky-register-button-section">
        <a className="sticky-register-button" href="https://docs.google.com/forms/d/e/1FAIpQLScExLpGXlhOmxqHuiiTTK99-zBjTwsWIX0EsZgcmaGWK_tH6g/viewform?usp=sf_link">Register</a>
    </div>
  </div>
}
  </div>
    
  )
}

export default StickyNav;
  
